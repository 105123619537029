import React, { useState, useEffect } from 'react';
import './Noticias.css';
import noticia1 from '../../assets/fondonoticias1.jpg';
import noticia2 from '../../assets/fondoplantilla.jpg'; 
import noticia3 from '../../assets/fondonoticias3.jpg';
import noticia4 from '../../assets/fondonoticias4.jpg';

import noticia0212_1 from '../../assets/noticias/noticia021224_1.png';
import noticia2511_1 from '../../assets/noticias/noticia25-11-1.png';

import noticia1811_1 from '../../assets/noticias/noticia1811_1.jpg';

import noticia1612_1 from '../../assets/noticias/noticia16_12.png';

import clasificacion1 from '../../assets/noticias/clasificacion1.png';
import clasificacion2 from '../../assets/noticias/clasificacion2.png';
import clasificacion3 from '../../assets/noticias/clasificacion3.png';
import clasificacion4 from '../../assets/noticias/clasificacion4.png';
import clasificacion5 from '../../assets/noticias/clasificacion5.png';
import clasificacion6 from '../../assets/noticias/clasificacion6.png';
import clasificacion7 from '../../assets/noticias/clasificacion7.png';


// Componente Modal para mostrar la noticia completa
const Modal = ({ show, onClose, title, date, content }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-noticias">
        <h2>{title}</h2>
        <p className="modal-date">{date}</p> {/* Fecha en el modal */}
        <div dangerouslySetInnerHTML={{ __html: content }} /> {/* Renderizar contenido HTML */}
        <button className="close-button" onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

const Card = ({ id, name = '', date = '', imageUrl, isOpen, handleClick, isMobile, onOpenModal }) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      setIsHovered(false);
    }
  }, [isMobile]);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
    }
  };

  return (
    <div
      className={`card ${isHovered || isOpen ? 'open' : ''}`}
      onClick={() => isMobile && handleClick(id)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ 
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        flex: isHovered || isOpen ? 2 : 1,
        position: 'relative'
      }}
    >
      <div className="card-overlay"></div>
      <div className="card-content">
        <h2 className="card-title">{name}</h2>
        <p className="card-date">{date}</p> {/* Fecha en la carta */}
        <button className="card-button" onClick={() => onOpenModal(id)}>Ver noticia completa</button>
      </div>
    </div>
  );
};

const Gallery = () => {
  const [openCardId, setOpenCardId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ title: '', date: '', content: '' });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (id) => {
    setOpenCardId(openCardId === id ? null : id);
  };

  // Contenido de las noticias
  const noticias = {
    '1': {
      title: 'Crónica 18 enero',
      date: '18 de enero de 2025',
      content:`
        <p>EQUIPO SENIOR</p>
        <p>Los tomelloseros volvieron a hacer valer su potencial en casa para vencer al colista
Manzanares que sigue sin sumar. Igualdad en el primer cuarto que nos llevaba a un
segundo donde la potencia anotadora de los auriverdes les llevaba al descanso con un
+16 a favor que sería clave para el devenir del partido. Quinta para los de Ramón Cañas'</p>
        <p>EQUIPO JUVENIL</p>
        <p>El equipo juvenil se desplazo hasta la localidad vecina, Daimiel. Allí se enfrentaron a
Enosentidos Luyra, el partido fue toda una batalla desde el inicio del salto hasta el final.
El marcador sufría seguidamente varios cambios de liderazgo incluso varios empates,
pero esta vez los azulones no pudieron imponerse y terminaron el partido perdiendo
de 6 puntos. El equipo liderado por Ricky sigue manteniendo su segunda posición.</p>

    `},
    '2': {
      title: 'Crónica 13 enero',
      date: '13 de enero de 2025',
      content: `
        <p>EQUIPO SENIOR</p>
        <p>Los moteños querían empezar el año con su quinta victoria y no fallaron en el final de esta primera vuelta. Triunfo ante el colista manzanareño en un partido que comenzó dominando el roster visitante con +7 en el primero pero que con el 33-19 del segundo por parte de los rojinegros se le daba por completo la vuelta al partido para acabar venciendo en una igualada segunda mitad.</p>
        <p>EQUIPO JUVENIL</p>
        <p>El equipo juvenil se enfretó frente a Valdepeñas en casa, fue un partido en todo momento igualado con varios cambios de liderazgo en todo momento. Pero al final los de Ricky no permitieron que el equipo visitante se hiciera con la victoria demostrando su dominio en pista.</p>
        `
    },
    '3': {
      title: 'Clasificaciones',
      date: ' ',
      content: `
      <p>ALEVÍN MASCULINO:</p>
      <img src="${clasificacion1}" alt="Noticia" style="width:80%; margin-top: 20px;" />
      <p>ALEVÍN FEMENINO:</p>
      <img src="${clasificacion2}" alt="Noticia" style="width:80%; margin-top: 20px;" />
      <p>INFANTIL MASCULINO:</p>
      <img src="${clasificacion3}" alt="Noticia" style="width:80%; margin-top: 20px;" />
      <p>INFANTIL FEMENINO:</p>
      <img src="${clasificacion4}" alt="Noticia" style="width:80%; margin-top: 20px;" />
      <p>CADETE MASCULINO:</p>
      <img src="${clasificacion5}" alt="Noticia" style="width:80%; margin-top: 20px;" />
      <p>JUVENIL U19 MASCULINO:</p>
      <img src="${clasificacion6}" alt="Noticia" style="width:80%; margin-top: 20px;" />
      <p>1º DIVISIÓN AUTONÓMICA:</p>
      <img src="${clasificacion7}" alt="Noticia" style="width:80%; margin-top: 20px;" />     
      `
    },
    '4': {
      title: 'Crónica 16 de diciembre',
      date: '16 de diciembre de 2024',
      content: `
       <p>Manzanares sigue sin sumar y UCA cerrará segundo el año en su grupo a una sola
victoria del líder. Dominó por la mínima el primer cuarto el equipo manzanareño para
ver como con el 12-24 del segundo se les iba el partido de las manos. Los albaceteños
en sus rotaciones mostraron más fortaleza y aprovecharon sus chances para sumar a
domicilio en el Antonio Caba.</p>
       <p>Nuestro equipo juvenil se desplazó hasta Pozuelo de Calatrava, el partido estuvo en
todo momento dominado por el equipo visitante. El equipo local luchaba con ganas
para poder alcanzar a los azulones, pero les fue complicado. Los de Ricky demostraron
su dominio en pista y no permitieron que en ningún momento ser alcanzados.</p>

      <img src="${noticia1612_1}" alt="Noticia" style="width:30%; margin-top: 20px;" />
      `
    }
  };

  const handleOpenModal = (id) => {
    const { title, date, content } = noticias[id];
    setModalData({ title, date, content });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <section className="gallery-container" id="galeria">
       <Card 
        id="1"
        name="Crónica 18 enero"
        date="18 de enero de 2025"
        imageUrl={noticia3}
        isOpen={openCardId === '1'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />
       <Card 
        id="2"
        name="Crónica 13 enero"
        date="13 de enero de 2025"
        imageUrl={noticia4}
        isOpen={openCardId === '2'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      /> 
       <Card 
        id="3"
        name="Clasificaciones"
        date=" "
        imageUrl={noticia1}
        isOpen={openCardId === '3'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />
      <Card 
        id="4"
        name="Crónica 16 diciembre"
        date="16 de diciembre de 2024"
        imageUrl={noticia2}
        isOpen={openCardId === '4'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />
      
      
      {/* Modal para mostrar la noticia completa */}
      <Modal 
        show={showModal} 
        onClose={handleCloseModal} 
        title={modalData.title} 
        date={modalData.date} 
        content={modalData.content} 
      />
    </section>
  );
};

export default Gallery;
