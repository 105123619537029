import React, { useState, useEffect } from 'react';
import './Header2.css'; // Archivo CSS para estilos
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.jpeg'; // Asegúrate de que la ruta sea correcta

const Header = () => {
    const [isAtTop, setIsAtTop] = useState(true);
    const navigate = useNavigate();

    const handleScroll = () => {
        setIsAtTop(window.scrollY === 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLinkClick = (id) => {
        navigate('/');
        setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    return (
        <header className={`header ${isAtTop ? 'at-top' : ''}`}>
            <div className="logo">
                <img src={logo} alt="Logo" className={`${isAtTop ? 'large-logo' : ''}`} />
            </div>
            <nav>
                <ul>
                    <li><Link to="#" onClick={() => handleLinkClick('home')} className={`${isAtTop ? '' : 'scrolled'}`}>Inicio</Link></li>
                    <li><Link to="#" onClick={() => handleLinkClick('galeria')} className={`${isAtTop ? '' : 'scrolled'}`}>Galería</Link></li>
                    <li><Link to="#" onClick={() => handleLinkClick('plantilla')} className={`${isAtTop ? '' : 'scrolled'}`}>Plantilla</Link></li>
                    <li><Link to="#" onClick={() => handleLinkClick('escuelas')} className={`${isAtTop ? '' : 'scrolled'}`}>Escuelas</Link></li>
                    <li><Link to="#" onClick={() => handleLinkClick('instalaciones')} className={`${isAtTop ? '' : 'scrolled'}`}>Instalaciones</Link></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
