import React, { useState, useEffect } from 'react';
import cbmanzanares from '../../assets/logo.jpeg';
import cervantes from '../../assets/escudos_equipos/cervantes.png';
import almagro from '../../assets/escudos_equipos/almagro.jpg';
import miguelturra from '../../assets/escudos_equipos/miguelturra.png';
import argamasilla from '../../assets/escudos_equipos/argamasilla.jpeg';
import tomelloso from '../../assets/escudos_equipos/tomelloso.png';
import puertollano from '../../assets/escudos_equipos/puertollano.png';
import pozuelo from '../../assets/escudos_equipos/pozuelo.jpg';
import valdepenas from '../../assets/escudos_equipos/valdepenas.png';
import daimiel from '../../assets/escudos_equipos/daimiel.png';

// VER RESULTADOS AQUÍ:
// https://fbclm.net/pagina-de-grupo/?id=786

const ResultadosPartidos = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Datos de partidos simulados
  const partidos = [
    {
      jornada: 1,
      local: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 68 },
      visitante: { nombre: 'CB Basket Cervantes', logo: cervantes, puntos: 45 },
    },
    {
      jornada: 2,
      local: { nombre: 'CB Almagro', logo: almagro, puntos: 43 },
      visitante: { nombre: 'CB Manzanares', logo: cbmanzanares, puntos: 56 },
    },
    {
      jornada: 3,
      local: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 57 },
      visitante: { nombre: 'CB Miguelturra', logo: miguelturra, puntos: 63 },
    },
    {
      jornada: 4,
      local: { nombre: 'Fibra CB Argamasilla', logo: argamasilla, puntos: 50 },
      visitante: { nombre: 'CB Manzanares', logo: cbmanzanares, puntos: 118 },
    },
    {
      jornada: 5,
      local: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 57 },
      visitante: { nombre: 'Valbrokers CB Tomelloso', logo: tomelloso, puntos: 63 },
    },
    {
      jornada: 6,
      local: { nombre: 'CD Basket Dentatis Puertollano', logo: puertollano, puntos: 73 },
      visitante: { nombre: 'CB Manzanares', logo: cbmanzanares, puntos: 82 },
    },
    {
      jornada: 7,
      local: { nombre: 'CB Pozuelo de Calatrava', logo: pozuelo, puntos: 33 },
      visitante: { nombre: 'CB Manzanares', logo: cbmanzanares, puntos: 89 },
    },
    {
      jornada: 8,
      local: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 62 },
      visitante: { nombre: 'CV CREA TU SABOR', logo: valdepenas, puntos: 56 },
    },
    {
      jornada: 9,
      local: { nombre: 'Enosentidos Luyra', logo: daimiel, puntos: 57 },
      visitante: { nombre: 'CB Manzanares', logo: cbmanzanares, puntos: 51 },
    },
  ];

  // Inicializa jornadaActual con la última jornada
  const [jornadaActual, setJornadaActual] = useState(partidos.length);

  // Función para cambiar la jornada
  const cambiarJornada = (direccion) => {
    if (direccion === 'previa' && jornadaActual > 1) {
      setJornadaActual(jornadaActual - 1);
    } else if (direccion === 'siguiente' && jornadaActual < partidos.length) {
      setJornadaActual(jornadaActual + 1);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Obtener el partido de la jornada actual
  const partido = partidos.find(p => p.jornada === jornadaActual);

  return (
    <div style={{ ...styles.container, padding: isMobile ? '10px' : '20px' }}>
      <p style={styles.categoria}>RESULTADOS - EQUIPO JUNIOR</p>

      <div style={{ ...styles.resultadosContainer, flexDirection: isMobile ? 'column' : 'row' }}>
        <button onClick={() => cambiarJornada('previa')} style={styles.button}>{'<'}</button>
        <h2 style={{ ...styles.jornada, fontSize: isMobile ? '20px' : '24px' }}>Jornada {jornadaActual}</h2>
        <button onClick={() => cambiarJornada('siguiente')} style={styles.button}>{'>'}</button>
      </div>

      {partido && (
        <div style={{ ...styles.partido, flexDirection: isMobile ? 'column' : 'row' }}>
          <div style={styles.equipo}>
            <img src={partido.local.logo} alt={partido.local.nombre} style={styles.logo} />
            <p style={{ fontSize: isMobile ? '14px' : '16px' }}>{partido.local.nombre}</p>
            <p style={styles.puntos}>{partido.local.puntos}</p>
          </div>
          <p style={{ ...styles.guion, fontSize: isMobile ? '30px' : '50px' }}>-</p>
          <div style={styles.equipo}>
            <img src={partido.visitante.logo} alt={partido.visitante.nombre} style={styles.logo} />
            <p style={{ fontSize: isMobile ? '14px' : '16px' }}>{partido.visitante.nombre}</p>
            <p style={styles.puntos}>{partido.visitante.puntos}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#20448c',
    backgroundImage: 'linear-gradient(to bottom right, #3e32ed, #16aef0)',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  resultadosContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  jornada: {
    margin: '0 20px',
  },
  categoria: {
    fontSize: '40px',
    marginBottom: '20px',
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: 'white',
    border: 'none',
    color: '#20448c',
    fontSize: '24px',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '30%',
  },
  partido: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  equipo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 20px',
  },
  logo: {
    width: '50px',
    height: '50px',
  },
  puntos: {
    fontSize: '30px',
    fontWeight: 'bold',
  },
  guion: {
    margin: '0 20px',
  },
};

export default ResultadosPartidos;
