import React from "react";
import LazyLoad from "react-lazyload";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import image1 from "../../assets/escuelas/benjamin.jpg";
import image2 from "../../assets/escuelas/alevinA.png";
import image3 from "../../assets/escuelas/alevinB.jpg";
import image4 from "../../assets/escuelas/alevinFEM.png";
import image5 from "../../assets/escuelas/infantil.png";
import image6 from "../../assets/escuelas/infantilFEM.png";
import image7 from "../../assets/escuelas/cadeteMasc.png";

const EscuelaImages = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Desplaza la ventana al principio de la página
  }, []); // Solo se ejecuta cuando se monta el componente

  const handleBackClick = () => {
    navigate(-1);
  };

  const images = [
    { src: image1, title: "Benjamín" },
    { src: image2, title: "Alevín 'A'" },
    { src: image3, title: "Alevín 'B'" },
    { src: image4, title: "Alevín Femenino" },
    { src: image5, title: "Infantil Masculino" },
    { src: image6, title: "Infantil Femenino" },
    { src: image7, title: "Cadete" },
  ];

  return (
    <div className="escuela-images-gallery-container">
      <button className="escuela-images-back-button" onClick={handleBackClick}>
        Volver
      </button>
      <h2 className="escuela-images-titulo">Galería de Imágenes de las Escuelas</h2>
      <div className="escuela-images-gallery">
        {images.map((image, index) => (
          <LazyLoad key={index} height={200} offset={100}>
            <div className="escuela-images-container">
              <img
                src={image.src}
                alt={image.title}
                className="escuela-images-image"
              />
              <p className="escuela-images-title">{image.title}</p>
            </div>
          </LazyLoad>
        ))}
      </div>
    </div>
  );
};

export default EscuelaImages;
