import React, { useState, useEffect } from 'react';
import cbmanzanares from '../../assets/logo.jpeg';
import tomelloso from '../../assets/escudos_equipos/tomelloso.png';
import marianistas from '../../assets/escudos_equipos/marianistas.png';
import miguelesteban from '../../assets/escudos_equipos/miguelesteban.jpeg';
import albacete from '../../assets/escudos_equipos/albacete.png';
import cervantes from '../../assets/escudos_equipos/cervantes.png';
import almagro from '../../assets/escudos_equipos/almagro.jpg';
import hellin from '../../assets/escudos_equipos/hellin.jpg';
import mota from '../../assets/escudos_equipos/mota.jpg';

const ResultadosPartidos = () => {
  const [isMobile, setIsMobile] = useState(false);

  const partidos = [
    { jornada: 1, local: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 63 }, visitante: { nombre: 'CB Tomelloso', logo: tomelloso, puntos: 69 } },
    { jornada: 2, local: { nombre: 'CB Prado Marianistas', logo: marianistas, puntos: 87 }, visitante: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 44 } },
    { jornada: 3, local: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 41 }, visitante: { nombre: 'CB Miguel Esteban', logo: miguelesteban, puntos: 61 } },
    { jornada: 4, local: { nombre: 'Albamendra Albacete Basket', logo: albacete, puntos: 60 }, visitante: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 58 } },
    { jornada: 5, local: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 61 }, visitante: { nombre: 'Cervantes Ciudad Real', logo: cervantes , puntos: 88 } },
    { jornada: 6, local: { nombre: 'CB Almagro La Comedia', logo: almagro, puntos: 75 }, visitante: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 65 } },
    { jornada: 7, local: { nombre: 'ADB Hellin', logo: hellin, puntos: 77 }, visitante: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 69 } },
    { jornada: 8, local: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 62 }, visitante: { nombre: 'CB UCA Albacete', logo: albacete , puntos: 75 } },
    { jornada: 9, local: { nombre: 'CB MOTA MAX INFRAESTRUCTURAS', logo: mota, puntos: 83 }, visitante: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 79 } },
    { jornada: 10, local: { nombre: 'Valbrokers CB Tomelloso', logo: tomelloso, puntos: 92 }, visitante: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 61 } }

  ];

  const [jornadaActual, setJornadaActual] = useState(partidos.length);

  const cambiarJornada = (direccion) => {
    if (direccion === 'previa' && jornadaActual > 1) {
      setJornadaActual(jornadaActual - 1);
    } else if (direccion === 'siguiente' && jornadaActual < partidos.length) {
      setJornadaActual(jornadaActual + 1);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const partido = partidos.find(p => p.jornada === jornadaActual);

  return (
    <div style={styles.container}>
      <p style={styles.categoria}>RESULTADOS - EQUIPO SENIOR</p>

      <div style={styles.resultadosContainer}>
        <button onClick={() => cambiarJornada('previa')} style={styles.button}>{'<'}</button>
        <h2 style={styles.jornada}>Jornada {jornadaActual}</h2>
        <button onClick={() => cambiarJornada('siguiente')} style={styles.button}>{'>'}</button>
      </div>

      {partido && (
        <div style={{ ...styles.partido, flexDirection: isMobile ? 'column' : 'row' }}>
          <div style={styles.equipo}>
            <img src={partido.local.logo} alt={partido.local.nombre} style={styles.logo} />
            <p style={styles.equipoNombre}>{partido.local.nombre}</p>
            <p style={styles.puntos}>{partido.local.puntos}</p>
          </div>

          <p style={styles.guion}>-</p>

          <div style={styles.equipo}>
            <img src={partido.visitante.logo} alt={partido.visitante.nombre} style={styles.logo} />
            <p style={styles.equipoNombre}>{partido.visitante.nombre}</p>
            <p style={styles.puntos}>{partido.visitante.puntos}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    padding: '20px',
    backgroundColor: '#20448c',
    backgroundImage: 'linear-gradient(to bottom right, #3e32ed, #16aef0)',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  resultadosContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    flexWrap: 'wrap',
  },
  jornada: {
    fontSize: '20px',
    margin: '0 10px',
  },
  categoria: {
    fontSize: '32px',
    marginBottom: '15px',
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: 'white',
    border: 'none',
    color: '#20448c',
    fontSize: '20px',
    padding: '8px',
    cursor: 'pointer',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  partido: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    width: '100%',
  },
  equipo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10px',
  },
  equipoNombre: {
    fontSize: '16px',
    textAlign: 'center',
  },
  logo: {
    width: '60px',
    height: '60px',
    marginBottom: '10px',
  },
  puntos: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  guion: {
    fontSize: '30px',
    margin: '10px',
  },
};

export default ResultadosPartidos;
