import React, { useState, useEffect } from 'react';
import cbmanzanares from '../../assets/logo.jpeg';
import daimiel from '../../assets/escudos_equipos/daimiel.png';
import argamasilla from '../../assets/escudos_equipos/argamasilla.jpg';
import criptana from '../../assets/escudos_equipos/criptana.jpg';
import valdepenas from '../../assets/escudos_equipos/valdepenas.png';
import socuellamos from '../../assets/escudos_equipos/socuellamos.png';
import tomelloso from '../../assets/escudos_equipos/tomelloso.png';
import alcazar from '../../assets/escudos_equipos/alcazar.png';
import pedromunoz from '../../assets/escudos_equipos/pedro_munoz.png';

const ResultadosPartidos = () => {
  const [isMobile, setIsMobile] = useState(false);

  const partidos = [
    {
      jornada: 1,
      local: { nombre: 'Alevín Fem. Daimiel', logo: daimiel, puntos: 48 },
      visitante: { nombre: 'Alevín Fem. Manzanares', logo: cbmanzanares, puntos: 12 },
    },
    {
      jornada: 1,
      local: { nombre: 'Alevín "B" Daimiel', logo: daimiel, puntos: 54 },
      visitante: { nombre: 'Alevín "B" Manzanares', logo: cbmanzanares, puntos: 14 },
    },
    {
      jornada: 1,
      local: { nombre: 'Alevín "A" Daimiel', logo: daimiel, puntos: 32 },
      visitante: { nombre: 'Alevín "A" Manzanares', logo: cbmanzanares, puntos: 35 },
    },
    {
      jornada: 2,
      local: { nombre: 'Cadete Masc. Manzanares', logo: cbmanzanares, puntos: 77 },
      visitante: { nombre: 'AYTO. Daimiel', logo: daimiel, puntos: 43 },
    },
    {
      jornada: 2,
      local: { nombre: 'Infantil Fem. Manzanares', logo: cbmanzanares, puntos: 22 },
      visitante: { nombre: 'AYTO. Daimiel', logo: daimiel, puntos: 42 },
    },

    {
      jornada: 3,
      local: { nombre: 'Cabezuelo CB Socuellamos', logo: socuellamos, puntos: 21 },
      visitante: { nombre: 'Alevín "A" Manzanares', logo: cbmanzanares, puntos: 62 },
    },
    {
      jornada: 3,
      local: { nombre: 'ED Argamasilla de Alba', logo: argamasilla, puntos: 48 },
      visitante: { nombre: 'Alevín "B" Manzanares', logo: cbmanzanares, puntos: 24 },
    },
    {
      jornada: 3,
      local: { nombre: 'Infantil Masc. Manzanares', logo: cbmanzanares, puntos: 2 },
      visitante: { nombre: 'AYTO. Valdepeñas', logo: valdepenas, puntos: 42 },
    },
    {
      jornada: 3,
      local: { nombre: 'Ayuntamiento de criptana', logo: criptana, puntos: 30 },
      visitante: { nombre: 'Infantil Fem. Manzanares', logo: cbmanzanares, puntos: 34 },
    },
    {
      jornada: 3,
      local: { nombre: 'ED Argamasilla de Alba', logo: argamasilla, puntos: 37 },
      visitante: { nombre: 'Cadete Manzanares', logo: cbmanzanares, puntos: 77 },
    },
    {
      jornada: 4,
      local: { nombre: 'Alevín "A" Manzanares', logo: cbmanzanares, puntos: 35 },
      visitante: { nombre: 'ED Argamasilla de Alba', logo: argamasilla, puntos: 22 },
    },
    {
      jornada: 4,
      local: { nombre: 'AYTO. Tomelloso', logo: tomelloso, puntos: 58 },
      visitante: { nombre: 'Alevín "B" Manzanares', logo: cbmanzanares, puntos: 18 },
    },
    {
      jornada: 4,
      local: { nombre: 'ED Criptana', logo: criptana, puntos: 56 },
      visitante: { nombre: 'Alevín Fem. Manzanares', logo: cbmanzanares, puntos: 16 },
    },
    {
      jornada: 4,
      local: { nombre: 'AYTO. Valdepeñas', logo: valdepenas, puntos: 40 },
      visitante: { nombre: 'Infantil Manzanares', logo: cbmanzanares, puntos: 44 },
    },
    {
      jornada: 4,
      local: { nombre: 'AYTO. Valdepeñas', logo: valdepenas, puntos: 43 },
      visitante: { nombre: 'Cadete Manzanares', logo: cbmanzanares, puntos: 57 },
    },

    {
      jornada: 5,
      local: { nombre: 'Alevin B Manzanares', logo: cbmanzanares, puntos: 11 },
      visitante: { nombre: 'Alevin A Manzanares', logo: cbmanzanares, puntos: 51 },
    },
    {
      jornada: 5,
      local: { nombre: 'Alevin Fem. Manzanares', logo: cbmanzanares, puntos: 35 },
      visitante: { nombre: 'AYTO. Tomelloso', logo: tomelloso, puntos: 18 },
    },
    {
      jornada: 5,
      local: { nombre: 'Infantil Masc. Manzanares', logo: cbmanzanares, puntos: 49 },
      visitante: { nombre: 'EDM Daimiel Azul', logo: daimiel, puntos: 45 },
    },
    {
      jornada: 5,
      local: { nombre: 'Grupo 76 Alkasar negro', logo: alcazar, puntos: 16 },
      visitante: { nombre: 'Infantil Fen. Manzanares', logo: daimiel, puntos: 24 },
    },
    {
      jornada: 5,
      local: { nombre: 'Cadete Masc. Manzanares', logo: cbmanzanares, puntos: 55 },
      visitante: { nombre: 'AYTO. Valdepeñas', logo: valdepenas, puntos: 43 },
    },
    {
      jornada: 6,
      local: { nombre: 'AYTO. Tomelloso', logo: tomelloso, puntos: 44 },
      visitante: { nombre: 'Alevin A Manzanares', logo: cbmanzanares, puntos: 51 },
    },
    {
      jornada: 6,
      local: { nombre: 'Pedro Muñoz Deporte', logo: pedromunoz, puntos: 59 },
      visitante: { nombre: 'Alevin B Manzanares', logo: cbmanzanares, puntos: 30 },
    },
    {
      jornada: 6,
      local: { nombre: 'EDM Daimiel Blanco', logo: daimiel, puntos: 46 },
      visitante: { nombre: 'Infantil Masc. Manzanares', logo: cbmanzanares, puntos: 6 },
    },
    {
      jornada: 6,
      local: { nombre: 'Infantil Fem. Manzanares', logo: cbmanzanares, puntos: 30 },
      visitante: { nombre: 'AYTO. Tomelloso', logo: tomelloso, puntos: 35 },
    },
    {
      jornada: 6,
      local: { nombre: 'AYTO. Tomelloso', logo: tomelloso, puntos: 2 },
      visitante: { nombre: 'Cadete Manzanares', logo: cbmanzanares, puntos: 42 },
    },
    {
      jornada: 7,
      local: { nombre: 'Alevín A Manzanares', logo: cbmanzanares, puntos: 20 },
      visitante: { nombre: 'Pedro Muñoz Deporte', logo: pedromunoz, puntos: 0 },
    },
    {
      jornada: 7,
      local: { nombre: 'Alevín B Manzanares', logo: cbmanzanares, puntos: 20 },
      visitante: { nombre: 'Ayto Tomelloso', logo: tomelloso, puntos: 0 },
    },
    {
      jornada: 7,
      local: { nombre: 'Alevín Fem. Manzanares', logo: cbmanzanares, puntos: 7 },
      visitante: { nombre: 'EB Criptana', logo: criptana, puntos: 47 },
    },
    {
      jornada: 7,
      local: { nombre: 'Grupo 76 Alkasar verde', logo: alcazar, puntos: 35 },
      visitante: { nombre: 'Infantil Fem. Manzanares', logo: cbmanzanares, puntos: 32 },
    },
    {
      jornada: 7,
      local: { nombre: 'Cadete Manzanares', logo: cbmanzanares, puntos: 51 },
      visitante: { nombre: 'Ayto Tomelloso', logo: tomelloso, puntos: 11 },
    },
    {
      jornada: 8,
      local: { nombre: 'Cadete Masc. Manzanares', logo: cbmanzanares, puntos: 69 },
      visitante: { nombre: 'ED. Argamasilla de Alba', logo: argamasilla, puntos: 29 },
    },
    // Añadir más partidos/jornadas aquí
  ];

  const [jornadaActual, setJornadaActual] = useState(partidos[partidos.length - 1].jornada);

  const cambiarJornada = (direccion) => {
    if (direccion === 'previa' && jornadaActual > 1) {
      setJornadaActual(jornadaActual - 1);
    } else if (direccion === 'siguiente' && jornadaActual < partidos[partidos.length - 1].jornada) {
      setJornadaActual(jornadaActual + 1);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const partidosDeJornadaActual = partidos.filter(p => p.jornada === jornadaActual);

  return (
    <div style={styles.container}>
      <p style={styles.categoria}>RESULTADOS - DEPORTE BASE</p>

      <div style={styles.resultadosContainer}>
        <button onClick={() => cambiarJornada('previa')} style={styles.button}>
          {'<'}
        </button>
        <h2 style={styles.jornada}>Jornada {jornadaActual}</h2>
        <button onClick={() => cambiarJornada('siguiente')} style={styles.button}>
          {'>'}
        </button>
      </div>

      <div style={styles.partidosContainer}>
        {partidosDeJornadaActual.map((partido, index) => (
          <div key={index} style={styles.partido}>
            <div style={styles.equipo}>
              <img src={partido.local.logo} alt={partido.local.nombre} style={styles.logo} />
              <p style={styles.nombreEquipo}>{partido.local.nombre}</p>
              <p style={styles.puntos}>{partido.local.puntos}</p>
            </div>
            <p style={styles.guion}>-</p>
            <div style={styles.equipo}>
              <img src={partido.visitante.logo} alt={partido.visitante.nombre} style={styles.logo} />
              <p style={styles.nombreEquipo}>{partido.visitante.nombre}</p>
              <p style={styles.puntos}>{partido.visitante.puntos}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#20448c',
    backgroundImage: 'linear-gradient(to bottom right, #3e32ed, #16aef0)',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  resultadosContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  jornada: {
    fontSize: '24px',
    margin: '0 10px',
  },
  categoria: {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  button: {
    backgroundColor: 'white',
    border: 'none',
    color: '#20448c',
    fontSize: '20px',
    padding: '8px',
    cursor: 'pointer',
    borderRadius: '50%',
  },
  partidosContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
  },
  partido: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '100%',
    textAlign: 'center',
    borderBottom: '1px solid white', // Agregado para el borde
    paddingBottom: '10px',          // Espacio debajo del contenido
    marginBottom: '10px',   
  },
  equipo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '40px',
    height: '40px',
  },
  nombreEquipo: {
    fontSize: '14px',
    wordWrap: 'break-word',
    maxWidth: '120px',
  },
  puntos: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  guion: {
    fontSize: '30px',
    margin: '10px 0',
  },
};

export default ResultadosPartidos;
